.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--dl-color-primary1-blue80);
}
.home-hero1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  min-height: auto;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-container1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-hero-heading {
  color: var(--dl-color-gray-white);
  font-size: 3rem;
  max-width: 800px;
  text-align: center;
  font-family: "Raleway";
  line-height: 1.6;
}
.home-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-container2 {
  flex: 1;
  width: 48px;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  margin-left: var(--dl-space-space-fiveunits);
  padding-top: 0px;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 2px;
  margin-right: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: 0px;
  border-top-width: 2px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container3 {
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-twounits);
  display: flex;
  margin-top: 0px;
  align-items: center;
  margin-left: 0px;
  padding-top: 0px;
  margin-right: 0px;
  padding-left: var(--dl-space-space-unit);
  margin-bottom: 0px;
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.home-text {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
}
.home-features {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  width: auto;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.home-container4 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-features-heading {
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-container5 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
@media(max-width: 991px) {
  .home-hero1 {
    flex-direction: column;
  }
  .home-container1 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
  }
  .home-hero-heading {
    text-align: center;
  }
  .home-container2 {
    width: 48px;
    margin-top: var(--dl-space-space-threeunits);
    margin-left: 0px;
    padding-top: 0px;
    border-color: var(--dl-color-gray-white);
    border-style: solid;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fiveunits);
    padding-bottom: 0px;
    border-top-width: 2px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-features {
    background-color: var(--dl-color-gray-white);
  }
}
@media(max-width: 767px) {
  .home-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container5 {
    grid-template-columns: 1fr;
  }
}
@media(max-width: 479px) {
  .home-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
