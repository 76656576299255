.om-census-ip-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.om-census-ip-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--dl-color-primary1-blue80);
}
.om-census-ip-hero1 {
  width: 100%;
  height: 874px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  min-height: auto;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.om-census-ip-container1 {
  width: 1033px;
  height: 449px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.om-census-ip-details-heading {
  color: var(--dl-color-gray-white);
  width: 80%;
  font-size: 3rem;
  text-align: left;
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.om-census-ip-text {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
}
@media(max-width: 991px) {
  .om-census-ip-hero {
    flex: 1;
    height: 100%;
    align-self: flex-start;
  }
  .om-census-ip-hero1 {
    height: auto;
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
  }
  .om-census-ip-container1 {
    width: 100%;
    height: auto;
  }
  .om-census-ip-text {
    color: var(--dl-color-gray-white);
    font-size: 1.5rem;
  }
}
@media(max-width: 767px) {
  .om-census-ip-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .om-census-ip-container1 {
    align-items: center;
    justify-content: flex-start;
  }
  .om-census-ip-details-heading {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .om-census-ip-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
